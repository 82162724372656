/**
 * ////////////////////  Font Style ////////////////////
 *
 * @format
 */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600;700;800;900&display=swap");

body {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #030912 !important;
}
* {
  font-family: "Poppins", sans-serif !important;
}
a {
  text-decoration: none !important;
}
/* ::placeholder {
    color: #6D7580;
    opacity: 1;
  }
  
  :-ms-input-placeholder {
    color: #6D7580;
  }
  
  ::-ms-input-placeholder {
    color: #6D7580;
  } */

/* ////////////////////// SCROLL COLOR ///////////////////////////// */

::-webkit-scrollbar {
  width: 5px;

  overflow: auto;
}

::-webkit-scrollbar-track {
  background: #fff;
  overflow: auto;
}

::-webkit-scrollbar-thumb {
  transform: scale(1.5);
  border-left: 10px #ffa800 solid;
}

::-webkit-scrollbar-thumb:hover {
  transform: scale(1.5);
  border-left: 10px #ffa800 solid;
}

/* /////////////////////////////////// TOOGLER ICON COLOR CHANGE COLOR ////////////////////////// */

.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none !important;
  position: relative;
  border-bottom: 2px solid #ffffff;
  transition: all 300ms linear;
}

.navbar .navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #ffffff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}

.navbar .navbar-toggler-icon:after {
  top: 8px;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}

.navbar-toggler {
  background-color: transparent !important;
  padding: 10px !important;
}

.offcanvas {
  backdrop-filter: blur(10px) !important;
  background-color: #00000080 !important;
}

/* ////////////////////////////    HEADER SECTION ////////////////////////// */

.Header-Selection {
  background: transparent !important;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  left: 0;
  z-index: 999;
  .navbar-nav {
    a {
      font-size: 1rem !important;
      margin: 0px 20px;
      font-weight: 600;
      color: #ffffff !important;
    }
  }
}
.navbar-brand {
  font-size: 30px !important;
  font-weight: 700;
  color: #ffffff !important;
}

/////////////////////  .HERO SECTION   ///////////////
.sec-bg2 {
  // background: #030b17;
  background: linear-gradient(to right, #030b17 50%, #0c111b00),
    url(../public/assets/bg.jpeg) no-repeat top right;
  background-size: contain;
  position: relative;
  min-height: auto;
  padding: 40px 0;
  padding-bottom: 60px;

  @media screen and (max-width: 600px) {
    background-size: cover;
  }
}
.sec-bg2-fixed--height {
  min-height: 1080px;
  width: 100%;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .sec-bg2-fixed--height {
    min-height: 600px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-bg2-fixed--height {
    min-height: auto;
    padding: 60px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sec-bg2-fixed--height {
    min-height: auto;
    padding: 80px 0;
    padding-bottom: 60px;
  }
}
.hero-section {
  .content {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;
    h1 {
      font-size: 2vw;
      font-weight: 700;
      color: #ffa800;
      top: 50%;
    }

    h5 {
      font-size: 18px;
      color: #a8a7a8;
      font-family: monospace;
      span {
        color: #ffa800;
      }
    }
    p {
      color: #a8a7a8;
      font-size: 26px;
    }
    .btn-social {
      border-radius: 20px;
      border: 2px solid #ffffff;
      padding: 7px 15px;
      color: #000;
      background: #ffffff;
      font-weight: 700;
      opacity: 1;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
.btn-social2 {
  border-radius: 15px !important;
  border: 2px solid #ffa800;
  padding: 20px;
  font-weight: 700 !important;

  color: #000 !important;
  background: #ffa800 !important;
  opacity: 1;
  &:hover {
    opacity: 0.8;
  }
}
////////////////////////  ABOUT SEREC

.About-section {
  h1 {
    font-size: 45px;
    line-height: 1.1em;
    margin: 0px;
    display: block;
    letter-spacing: -1px;
  }

  h4 {
    margin-bottom: 20px;
    font-size: 30px;
    font-family: monospace;
  }
}

// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
//                               Roadmap
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================

.roadmap h2 {
  font-size: 40px;
  font-style: italic;
  color: #ffffff;
  line-height: 1.1em;
  margin: 0px;
  display: block;
  letter-spacing: -1px;
  text-align: center;
}
.roadmap .partner-container li {
  color: #fff !important;
  margin-top: 10px;
  list-style-type: square;
  
}
.roadmap .partners {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  padding-bottom: 70px;
}
.roadmap .partners .partner-container {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 100%;
  padding: 50px 50px 0;
  position: relative;
::marker{
  color: #ffa800;
}
}
.roadmap .gradient-text {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(0deg, #ffa800 0, #ffaa0082);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.roadmap .logo-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.roadmap .logo-container a {
  display: flex;
  flex: 1;
  max-height: 100%;
  max-width: 40%;
}
.roadmap .logos {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  justify-content: space-around !important;
  align-content: space-around;
}

@media screen and (min-width: 932px) {
  .roadmap .partner-container:not(:last-of-type):after {
    background: linear-gradient(0deg, #ffa800 0, #ffaa0082);
    bottom: 8px;
    content: "";
    height: calc(80% - 50px);
    position: absolute;
    right: 0;
    width: 3px;
  }
}
@media screen and (max-width: 1300px) {
  .roadmap .partner-container {
    padding: 50px 30px 0;
  }
}
/////////////// Tokenomics-section  /////////

.Tokenomics-section {
  h1 {
    font-size: 20px;
    color: #ffffff;
    line-height: 1.1em;
    margin: 0px;
    display: block;
    letter-spacing: -1px;
  }
  h2 {
    font-size: 40px;
    font-style: italic;
    color: #ffffff;
    line-height: 1.1em;
    margin: 0px;
    display: block;
    letter-spacing: -1px;
  }
  h4 {
    font-size: 1.45rem !important;
    line-height: 35px;
    color: #ffffff;
  }
  .row {
    border: 1px solid #fff;
  }
}

///////////////////////  Tokenomics-section

.Tokenomics-section {
  .bg-card {
    border-radius: 20px;
    text-align: center;
  }
}

//////////////// Doge-section  /////

.Doge-section {
  h1 {
    font-size: 45px;
    line-height: 1.1em;
    margin: 0px;
    display: block;
    letter-spacing: -1px;
    text-align: center;
  }
}

/* ///////////////////////////////  Footer section  ////////////////////////*/

.Footer-Section {
  background: #000;
  padding: 10px 20px;
  .copyright {
    // border-top: 1px solid #dcdcdc;
    p {
      font-size: 1rem;
      color: #999;
    }
  }
  a {
    color: #fff;
    font-weight: 600;
  }
}

///////////  MEDIA QUERRY //////////

@media only screen and (max-width: 600px) {
  .hero-section .content {

    h1 {
      font-size: 30px;
    }
    h5 {
      font-size: 19px;
    }
    .btn-sec {
      justify-content: center;
    }
  }
}
